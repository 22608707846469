const debounce = (callback, wait) => {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
}

function throttle(cb, delay = 100) {
  let shouldWait = false
  let waitingArgs
  const timeoutFunc = () => {
    if (waitingArgs == null) {
      shouldWait = false
    } else {
      cb(...waitingArgs)
      waitingArgs = null
      setTimeout(timeoutFunc, delay)
    }
  }

  return (...args) => {
    if (shouldWait) {
      waitingArgs = args
      return
    }

    cb(...args)
    shouldWait = true
    setTimeout(timeoutFunc, delay)
  }
}

const animateCSS = (element, animation, prefix = 'animate__') =>
  // We create a Promise and return it
  new Promise((resolve, reject) => {
    const animationName = `${prefix}${animation}`;
    const node = document.querySelector(element);

    node.classList.add(`${prefix}animated`, animationName);

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event) {
      event.stopPropagation();
      node.classList.remove(`${prefix}animated`, animationName);
      resolve('Animation ended');
    }

    node.addEventListener('animationend', handleAnimationEnd, { once: true });
  });



/**
 * Modals
 */
function openModal(id) {
  var modal = document.getElementById(id);
  var closeIcon = modal.querySelector('.close');
  var active = false;

  modal.classList.add("active");
  document.body.classList.add("modal-open");
  active = true;

  // create inner div for scrolling
  var els = modal.querySelectorAll("#" + id + " > *");
  var inner = document.createElement("div");
  modal.prepend(inner);
  els.forEach((el) => {
    inner.prepend(el);
  });

  if (closeIcon == null) {
    closeIcon = document.createElement("span");
    closeIcon.classList.add("close")
    modal.append(closeIcon);
  }

  function closeWindow() {
    if (modal !== null) {
      modal.classList.remove("active");
      document.body.classList.remove("modal-open");
      active = false;
    }
  }

  if (closeIcon !== null) {
    closeIcon.addEventListener("click", function (event) {
      event.preventDefault();
      closeWindow();
    })
  }

  document.addEventListener("keydown", function (event) {
    if (active && event.key === 'Escape') {
      closeWindow();
    }
  })
}

/**
 * Suche
 */
(function ($) {
  window.addEventListener("DOMContentLoaded", function () {


    var searchIcons = document.querySelectorAll(".openSearch");
    var popup = document.querySelector(".search-popup");
    var closeIcon = document.querySelector('.search--close');
    var active = false;

    function closeSearchWindow() {
      if (popup !== null) {
        popup.classList.remove("active");
        active = false;
      }
    }

    for (var i = 0; i < searchIcons.length; i++) {
      searchIcons[i].addEventListener("click", function () {
        if (popup !== null) {
          popup.classList.add("active");
          active = true;
          setTimeout(function () {
            popup.querySelector("input").focus();
          }, 100);
        }
      })
    }

    if (closeIcon !== null) {
      closeIcon.addEventListener("click", function (event) {
        event.preventDefault();
        closeSearchWindow();
      })
    }

    document.addEventListener("keydown", function (event) {
      if (active && event.key === 'Escape') {
        closeSearchWindow();
      }
    })

  });
})();


/**
 * Changelanguage
 */
(function ($) {
  document.addEventListener("DOMContentLoaded", function () {


    var openChangelanguageIcon = document.querySelector(".openChangelanguage");
    var popup = document.querySelector(".mod_changelanguage");
    var closeIcon = document.querySelector('.mod_changelanguage .close');
    var active = false;

    function closeWindow() {
      if (popup !== null) {
        popup.classList.remove("active");
        active = false;
      }
    }


    if (openChangelanguageIcon) {
      openChangelanguageIcon.addEventListener("click", function () {
        if (popup !== null) {
          popup.classList.add("active");
          active = true;
        }
      })

      if (closeIcon !== null) {
        closeIcon.addEventListener("click", function (event) {
          event.preventDefault();
          closeWindow();
        })
      }

      document.addEventListener("keydown", function (event) {
        if (active && event.key === 'Escape') {
          closeWindow();
        }
      })
    }

  });
})();



/**
 * Top-Button
 */
/*
(function($) {
  var topbutton = document.getElementById("top-button");
  var wrapper = document.getElementById("wrapper");

  wrapper.after(topbutton);

  topbutton.addEventListener("click", function(evt) {
    evt.preventDefault();
    window.scrollTo(0,0);
  })
})();
*/

/**
 * Scrolled
 */
(function ($) {

  const handleScrollEvent = debounce((ev) => {
    if (scrollY > 300) {
      document.querySelector("body").classList.add("scrolled");
    } else {
      document.querySelector("body").classList.remove("scrolled");
    }
  }, 250);

  window.addEventListener('scroll', handleScrollEvent);
})();


/**
 * Sprechblase
 */
(function ($) {
  window.addEventListener("DOMContentLoaded", function () {
    var sprechblase = document.getElementById("sprechblase");

    if (sprechblase) {
      var footer = document.getElementById("footer");
      footer.after(sprechblase);
      sprechblase.addEventListener("click", function (evt) {
        sprechblase.classList.toggle("active");
      });
    }
  });
})();


/**
 * Navi hide on Scroll
 */

(function ($) {
  window.addEventListener("DOMContentLoaded", function () {
    var topbarHeight = document.getElementById("header").offsetHeight;
    var prevScrollpos = window.pageYOffset;

    function checkScrolling() {
      var currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        document.body.classList.remove("scrolling-down");
      }

      if (prevScrollpos < currentScrollPos && window.pageYOffset > 50) {
        document.body.classList.add("scrolling-down");
        document.body.classList.add("scrolling");
      }

      if (window.pageYOffset == 0) {
        document.body.classList.remove("scrolling");
        document.getElementById("header").classList.remove("white");
      } else if (window.pageYOffset > topbarHeight) {
        document.getElementById("header").classList.add("white");
      }

      prevScrollpos = currentScrollPos;
    }

    //window.onscroll = checkScrolling;
    //checkScrolling();
  });
})();


/**
 * Scrolling lines 
 */
(function ($) {
  window.addEventListener("DOMContentLoaded", function () {
    var offset = 0;

    var els = document.querySelectorAll(".ce_rsce_wrapper_start.lines");

    // add divs for Lines
    els.forEach((el) => {

      var isOneway = el.classList.contains("oneway");
      var cols = el.querySelectorAll(":scope > div");

      for (i = 0; i < cols.length - 1; i++) {

        cols[i].style.position = "relative";

        var lineH = document.createElement("div");
        var lineInside = document.createElement("div");
        lineH.prepend(lineInside);
        lineH.classList.add("scrollline", "lineH", "lineBetween");
        if (isOneway) {
          lineH.classList.add("oneway");
        }
        cols[i].after(lineH);

        var lineV = document.createElement("div");
        var lineInside = document.createElement("div");
        lineV.prepend(lineInside);
        lineV.classList.add("scrollline", "lineV");
        cols[i].append(lineV);
      }

      var firstLine = document.createElement("div");
      firstLine.classList.add("scrollline", "lineH");
      if (isOneway) {
        firstLine.classList.add("oneway");
      }
      el.prepend(firstLine);
      var lastLine = document.createElement("div");
      lastLine.classList.add("scrollline", "lineH");
      if (isOneway) {
        lastLine.classList.add("oneway");
      }
      el.append(lastLine);
    });

    var linesH = document.querySelectorAll(".lineH");
    var linesV = document.querySelectorAll(".lineV");

    function isInViewport(element) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.top <= (window.innerHeight || document.documentElement.clientHeight) - offset &&
        rect.left <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    // change width/height of the lines
    const checkLines = throttle(function (evt, t) {
      linesH.forEach((el) => {
        if (isInViewport(el)) {
          const computedStyle = getComputedStyle(el.parentElement);
          var parentWidth = el.parentElement.clientWidth;
          parentWidth -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
          var y = el.getBoundingClientRect().top;
          var h = window.innerHeight;
          var percent;
          percent = (100 - (100 * y / h)) * 2;
          percent = (percent > 100) ? 100 : percent;
          //console.log(percent);
          //var newWidth = (parentWidth * percent / 100) - 40;
          var newWidth = (parentWidth * percent / 100);
          var elWidth = el.style.width;
          elWidth = (elWidth == "") ? 0 : parseInt(elWidth.slice(0, -2));
          var isOneway = el.classList.contains("oneway");
          if (isOneway && newWidth > elWidth) {
            el.style.width = newWidth + "px";
          } else if (!isOneway || (!!evt && evt.type == "resize")) {
            el.style.width = newWidth + "px";
          }
        }
      });
      linesV.forEach((el) => {
        if (isInViewport(el)) {
          var y = el.getBoundingClientRect().top;
          var h = window.innerHeight;
          var percent;
          percent = (100 - (100 * y / h));
          percent = (percent > 100) ? 100 : percent;
          var elHeight = el.style.height;
          elHeight = (elHeight == "") ? 0 : el.style.height.slice(0, -1);
          if (percent > elHeight) {
            el.style.height = percent + "%";
          }
        }
      });
    }, 10);

    //document.addEventListener("scroll", checkLines);
    //window.addEventListener("resize", checkLines);
    //checkLines();

    // detect how many cols are use to show/hide lines
    const countColumnsForLines = throttle(function () {
      var els = document.querySelectorAll(".ce_rsce_wrapper_start.lines");
      els.forEach((el) => {
        el.classList.remove("c2", "c3", "c4");
        var someEl = el.querySelector(":not(.scrollline)");
        var colStart = getComputedStyle(someEl).getPropertyValue("grid-column-start").substring(5);
        var numberOfCols;

        switch (colStart) {
          case "3": el.classList.add("c4"); numberOfCols = 4; break;
          case "4": el.classList.add("c3"); numberOfCols = 3; break;
          case "6": el.classList.add("c2"); numberOfCols = 2; break;
          case "12": el.classList.add("c1"); numberOfCols = 1; break;
        }

        var linesBetween = el.querySelectorAll(".lineBetween");

        for (var i = 0; i < linesBetween.length; i++) {
          linesBetween[i].classList.remove("hide");
          if ((i + 1) % numberOfCols != 0) {
            linesBetween[i].classList.add("hide");
          }
        }

        var linesV = el.querySelectorAll(".lineV");

        for (var i = 0; i < linesV.length; i++) {
          linesV[i].classList.remove("hide");
          if ((i + 1) % numberOfCols == 0) {
            linesV[i].classList.add("hide");
          }
        }
      })
    }, 200);


    window.addEventListener("resize", countColumnsForLines);
    countColumnsForLines();

  });
})();


/**
 * AOS
 */
/*
(function($) {
  window.addEventListener("DOMContentLoaded", function() {
    var els = document.querySelectorAll("#main .mod_article, .aos");
    els.forEach((el) => {
      el.dataset.aos = "fade-up";
      el.dataset.aosOnce = "false";
      el.dataset.aosDuration = "600";
    })
    
    AOS.init({
      offset: 100
    });
  });
})();
*/


/**
 * Delayed
 */
/*
 (function($) {
  window.addEventListener("DOMContentLoaded", function() {
    setTimeout(() => {
        var els = document.querySelectorAll(".delayed");
        els.forEach((el) => {
        //el.classList.add("active");
      })
    }, 500);
  });
})();
*/


/**
 * Nav-Mobile
 */
(function ($) {
  window.addEventListener("DOMContentLoaded", function () {
    var hamburger = document.querySelector("#nav-toggler");
    hamburger.addEventListener("click", function (evt) {
      evt.preventDefault();
      if (!!document.getElementById("sprechblase")) {
        //document.getElementById("sprechblase").classList.remove("active");
      }
      //document.body.classList.toggle("nav-mobile-open");
    });
  });
})();

/**
 * Top-Bar wird beim scrollen verkleinert (bis auf 0 Px)
 * der Header wird ein paar Pixel verkleinert
 */
(function ($) {
  window.addEventListener("DOMContentLoaded", function () {
    var headerFixed = document.body.classList.contains('header-fixed');
    var headerFixedPadding = document.body.classList.contains('header-fixed-padding');
    var wrapper = document.querySelector("#wrapper");
    var topbar = document.querySelector("#topbar");
    var header = document.querySelector("#header");
    var headerInside = document.querySelector("#header > .inside");
    var topbarOrigHeight;
    var headerOrigHeight = headerInside.offsetHeight;
    var headerMinHeight, topbarMinHeight;
    var documentHeight = window.innerHeight || document.documentElement.clientHeight;
    var topPosition;

    if (headerFixed) {
      headerInside.style.height = 0;
      headerMinHeight = headerInside.offsetHeight;
    }

    if (headerFixed && topbar) {
      topbarOrigHeight = topbar.offsetHeight;
      topbar.style.height = 0;
      topbarMinHeight = topbar.offsetHeight;
    }

    function onScrolling() {

      var offset = window.pageYOffset || document.documentElement.scrollTop || 0;
      offset = Math.min(
        document.documentElement.scrollHeight - documentHeight,
        offset
      );
      offset = Math.max(0, offset);
      //console.log("Offset ", offset);

      if (headerInside) {
        headerInside.style.height = Math.max(headerMinHeight, headerOrigHeight - offset) + 'px';
        offset -= headerOrigHeight - Math.max(headerMinHeight, headerOrigHeight - offset);
      }

      if (topbar) {
        topPosition = Math.max(0, topbarOrigHeight - offset);
        header.style.top = topPosition + "px";
        topbar.style.height = Math.max(topbarMinHeight, topbarOrigHeight - offset) + 'px';
        offset -= topbarOrigHeight - Math.max(topbarMinHeight, topbarOrigHeight - offset);
      }

      if (headerFixedPadding && wrapper.style.paddingTop !== (topbarOrigHeight + headerOrigHeight) + 'px') {
        wrapper.style.paddingTop = (topbarOrigHeight + headerOrigHeight) + 'px';
      }

      if (topPosition <= 0) {
        document.body.classList.add("scrolledheader");
      } else {
        document.body.classList.remove("scrolledheader");
      }
    };

    if (headerFixed) {
      window.addEventListener("scroll", onScrolling);
      onScrolling();
    }
  });
})();


(function ($) {
  window.addEventListener("load", function () {
    const parallaxElements = document.querySelectorAll('.parallax')

    if(parallaxElements.length > 0) {
      new simpleParallax(parallaxElements, {
        scale: 1.7
      });
    }

    
  });
})();